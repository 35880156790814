export enum IncidentEnums {
  SENTINEL_VALIDATION = 'Sentinel key is required',
  SENTINEL_SUCCESS_MESSAGE = 'Sentinel key updated successfully',
  SENTINEL_ERROR_MESSAGE = 'Failed to update sentinel key',
  DESCRIPTION_AMOUNT = 'Aggregate Coverage Amount',
  DESCRIPTION_DATE = 'Validation Date',
  INSURANCE_DETAIL = 'insurance Detail',
  PENDING = 'Pending',
  VALID = 'Valid',
  INVALID = 'Invalid',
  RISK_SCORE = 'Risk Score',
  THREAT_STATUS = 'threatStatus',
  AGENT_DETECTION_INFO = 'Agent Detection Info',
  GROUP_ID = 'Group Id',
  GROUP_NAME = 'Group Name',
  AGENT_OS_NAME = 'Agent Os Name',
  THREAT_NAME = 'Threat Name',
  THREAT_INFO = 'Threat Info',
  CREATED_AT = 'Created At',
  SUCCESS = 'success',
}

export enum ApiKey {
  incidents = 'incidents',
  list = 'list',
  threats = 'threats',
  detail = 'detail',
  incidentsHash = 'incidentshash',
  CoveLisiting = 'CoveLisiting',
  coveThreats = 'cove Threats',
  NSightPatchLisiting = 'NSightPatch Lisiting',
  NSightPatchThreats = 'NSightPatch Threats',
  MISP = 'misp',
}
export enum FileExtension {
  PDF = '.pdf',
  IMAGE = '.img',
  DOCX = '.docx',
}

export enum ResendVerification {
  TEXT = 'Please enter the Email address associated with your account and we will send you an Email to verify your account',
  SUCCESS_TEXT = 'We have sent a verification Email on your account, please visit your Email account',
}
export enum TabNavigation {
  Tab0 = 0,
  Tab1 = 1,
  Tab2 = 2,
}
export enum NSightPatchEnum {
  STATUSLABEL = 'statusLabel',
  PATCHURL = 'patchUrl',
  SEVERITYLABEL = 'severityLabel',
}
export enum AddUser {
  ERROR = 'invalid affiliate id',
  AFFILIATE_ID_ERROR = 'Please enter the correct affiliate ID to continue.',
}
export enum Mitigation_Status {
  NOT_MITIGATED = 'Not Mitigated',
  MITIGATED = 'mitigated',
}
export enum PolicyParamError {
  POLICY_PARAM_ERROR_API = 'policies.0.policyParameterId must be a positive number',
  CUSTOM_ERROR = 'Select Policy Parameters',
}
export enum PHONE_VALIDATION {
  PHONEERROR = 'Please enter valid area code',
}
export enum ReactQueryStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
  ERROR = 'error',
  LOADING = 'loading',
  IDLE = 'idle',
}

export enum ENIVORNMENT_ENUM {
  DEVELOPMENT = 'development',
}
